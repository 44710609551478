<template>
  <div>
    <s-crud
      title="Vehículos"
      ref="gridVehicle"
      
      edit
      add
      :filter="filter"
      add-no-function
      searchInput
      sortable
      remove
      excel
      :config="config"
      @rowSelected="rowSelected($event)"
      @add="add()"
      @edit="edit()"
      @save="saveVeh($event)"
    >
      <template v-slot:VehEnsuredEnd="{ row }">
        <v-chip dark x-small :color="colorEnsuredr(row)" style="font-size:12px;">
          {{ formatDateView(row.VehEnsuredEnd) || "Pendiente" }}
        </v-chip>
      </template>
      <template v-slot:VehPolicyEnd="{ row }">
        <v-chip dark x-small :color="colorPolicy(row)" style="font-size:12px;">
          {{ formatDateView(row.VehPolicyEnd) || "Pendiente" }}
        </v-chip>
      </template>
      <template v-slot:VehTechnicalReviewEnd="{ row }">
        <v-chip dark x-small :color="colorTechnicalReview(row)" style="font-size:12px;">
          {{ formatDateView(row.VehTechnicalReviewEnd) || "Pendiente" }}
        </v-chip>
      </template>
      <template v-slot:VehGpsEnd="{ row }">
        <v-chip dark x-small :color="colorGPS(row)" style="font-size:12px;">
          {{ formatDateView(row.VehGpsEnd) || "Pendiente" }}
        </v-chip>
      </template>
    </s-crud>

    <v-dialog max-width="1200" v-model="dialog" persistent>
      <vehicle-edit :typeArea="typeArea" :areID="areID" @save="save()" @close="dialog = false" v-model="vehID" />
    </v-dialog>
  </div>
</template>

<script>
import _sVehicle from "@/services/Management/VehicleService";
import vehicleEdit from "@/views/Management/Vehicle/VehicleEdit";
export default {
  components: { vehicleEdit },
  props:{
    typeArea: {type: Number, default: 1}
  },
  data: () => ({
    areID :null,
    filter:{TypeArea:1},
    config: {
      service: _sVehicle,
      model: {
        VehID: "ID",
        TypeVehicle: "int",
        VehPlate: "string",
        VehMotor: "string",
        VehSerie: "string",
        VehEnsuredEnd: "date",
        VehPolicyEnd: "date",
        VehTechnicalReviewEnd: "date",
        VehGpsEnd: "date",
        VehMileage: 0,
        VehStatus: 1,
      },
      headers:  [
       
        {
          text: "Vehículo",
          value: "TypeVehicleText",
          sortable: true,
        },
        {
          text: "Placa",
          value: "VehPlate",
          width: "100"
        },
        {
          text: "Ruc",
          value: "VehRuc",
        },
        {
          text: "Razon Social",
          value: "VehBusinessName",
          width: 200
        },
        {
          text: "Marca",
          value: "TypeBrandText",

          sortable: true,
        },
        {
          text: "Color",
          value: "TypeColorText",
          align: "center",
        },
        {
          text: "KM",
          value: "VehMileage",
          align: "end",
        }, 
        {
          text: "Constancia de Inscripción N°",
          value: "RegistrationConstancy",
          align: "end",
        },
        {
          text: "SOAT",
          value: "VehEnsuredEnd",
        },
        {
          text: "Póliza",
          value: "VehPolicyEnd",
        },
        {
          text: "Rev Tca",
          value: "VehTechnicalReviewEnd",
        },

        {
          text: "GPS",
          value: "VehGpsEnd",
        },

        {
          text: "Estado",
          value: "VehStatusText",
          align: "center",
          sortable: true,
        },
      ],
    },
    selected: {},
    verificationDay: "",
    dialog: false,
    vehID: 0,
  }),
  created() {
   //this.filter.AreID= this.$fun.getUserInfo().CtrContract.AreID;

    if(this.typeArea == 2){
          this.config.headers.splice(0, 1, {
            text: "Tipo Veh.",
            value: "TypeVehicleBrandName",
            width: 60
          });
    }

   this.filter.TypeArea = this.typeArea

    this.verificationDay = this.moment()
      .add(15, "days")
      .format(this.$const.FormatDateMoment);
  },
  methods: {
    formatDateView(value) {
      return this.$fun.formatDateView(value);
    },
    rowSelected(item) {
      this.selected = item;
      console.log("🚀 ~ file: Vehicle.vue ~ line 167 ~ rowSelected ~ this.selected", this.selected)
      
    },
    add() {
      this.dialog = true;
      console.log(this.dialog);
      // this.vehID = 0;
    },
    edit() {
      this.vehID = this.selected.length > 0 ? this.selected[0].VehID : 0;
      this.dialog = true;
    },
    save() {
      this.$refs.gridVehicle.refresh();
    },
    colorEnsuredr(item) {
      let color = "grey";
      if (item.VehEnsuredEnd != "" && item.VehEnsuredEnd != null) {
        if (this.verificationDay >= item.VehEnsuredEnd) color = "warning";
        else color = "success";
      }

      return color;
    },
    colorPolicy(item) {
      let color = "grey";
      if (item.VehPolicyEnd != "" && item.VehPolicyEnd != null) {
        if (this.verificationDay >= item.VehPolicyEnd) color = "warning";
        else color = "success";
      }

      return color;
    },
    colorTechnicalReview(item) {
      let color = "grey";
      if (item.VehTechnicalReviewEnd != "" && item.VehTechnicalReviewEnd != null) {
        if (this.verificationDay >= item.VehTechnicalReviewEnd) color = "warning";
        else color = "success";
      }

      return color;
    },
    colorGPS(item) {
      let color = "grey";
      if (item.VehGpsEnd != "" && item.VehGpsEnd != null) {
        if (this.verificationDay >= item.VehGpsEnd) color = "warning";
        else color = "success";
      }

      return color;
    },
  },
  computed: {},
};
</script>
